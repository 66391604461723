


















































































.repeater-row {
  &-add, &-remove {
    font-size: 25px;
    cursor: pointer;
    margin-right: 5px;
  }
}
